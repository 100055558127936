import { useTranslations } from 'use-intl';
import Cookies from 'cookies';
import { GetServerSidePropsContext } from 'next';
import { MainLayout } from '@/components/layout';
import { PageHead } from '@/components/common';
import { HomePageApi, TPageDataContent } from '@/shared/api/homepage-api';
import { PageMap } from '@/components/home';
import { mapSEOTags } from '@/shared/lib';
import { TSEOComponentData } from '@/shared/types';
import * as Sentry from '@sentry/browser';
import { getSplitTestComponents, SplitTestName } from '@/middleware';
import { TestComponent } from '@core/sections';

type IPageHomeProps = {
  messages: unknown;
  pageData: TPageDataContent[];
  pageDataABTesting: TPageDataContent[];
  seo: TSEOComponentData;
  abTestIndex?: number;
};

const PageHome = (props: IPageHomeProps) => {
  const { pageData, seo } = props;

  const seoTagsData = mapSEOTags(seo);
  const t = useTranslations();

  // тест сборки

  return (
    <>
      <PageHead title={t('nav.home')} seo={seoTagsData} />

      <MainLayout>
        <TestComponent />

        {pageData?.map((content, index) =>
          content ? <PageMap key={index} content={content} /> : null
        )}
      </MainLayout>
    </>
  );
};

export default PageHome;

export const getServerSideProps = async ({
  req,
  res,
  locale,
}: GetServerSidePropsContext) => {
  const api = new HomePageApi(locale || 'en');
  const cookies = new Cookies(req, res);

  let pageData;

  try {
    pageData = await api.getHomepageData({
      isByCreditLine: cookies.get(SplitTestName.CREDIT_LINE),
      isTambadanaHomepageB: Boolean(
        cookies.get(SplitTestName.AB_TAMBADANA_MAIN_PAGE) &&
          cookies.get(SplitTestName.AB_TAMBADANA_MAIN_PAGE) === 'B'
      ),
    });
  } catch (e) {
    Sentry.captureException(e);
  }

  const content = getSplitTestComponents(
    pageData?.attributes?.content ?? null,
    cookies
  );

  return {
    props: {
      messages: require(`../shared/lib/i18n/locales/${locale}.json`),
      pageData: content || null,
      seo: pageData?.attributes?.seo || null,
    },
  };
};
